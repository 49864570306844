import cl from "./Header.module.css";
import logo from "../../../src/assets/identity/logo.png";
import icon4 from "../../../src/assets/icons/header/xmark-solid.svg";
import icon5 from "../../../src/assets/icons/header/bars-solid.svg";

import Nav from "./Nav";
import { Toaster } from "react-hot-toast";

function Header() {
  function toggleMenu(e) {
    e.preventDefault();
    document.getElementById("menuIcon").classList.toggle(`${cl.active}`);
    document.getElementById("navMobile").classList.toggle(`${cl.display}`);
    document.getElementById("menuBar").classList.toggle(`${cl.hide}`);
    document.getElementById("crossMarkMenu").classList.toggle(`${cl.hide}`);
    document.body.classList.toggle(`${cl.overflow_hide}`);
  }

  // Function to close the menu when a NavLink is clicked
  function closeMenuOnNavClick() {
    document.getElementById("menuIcon").classList.remove(`${cl.active}`);
    document.getElementById("navMobile").classList.remove(`${cl.display}`);
    document.getElementById("menuBar").classList.remove(`${cl.hide}`);
    document.getElementById("crossMarkMenu").classList.remove(`${cl.hide}`);
    document.body.classList.remove(`${cl.overflow_hide}`);
  }

  return (
    <>
      <div className={cl.container}>
        <Toaster position="top-right" />
        <div className={cl.logo}>
          <a href="/">
            <img src={logo} alt="Logo" />
          </a>
        </div>
        <div className={cl.nav}>
          <Nav onNavLinkClick={closeMenuOnNavClick} />
        </div>
        <div className={cl.icons}>
          <ul>
            <li>
              <a href="#!" id="menuIcon" onClick={toggleMenu}>
                <img src={icon5} alt="Icon" id="menuBar" />
                <img
                  src={icon4}
                  alt="Icon"
                  className={cl.hide}
                  id="crossMarkMenu"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={cl.navMobile} id="navMobile">
        <Nav onNavLinkClick={closeMenuOnNavClick} />
      </div>
    </>
  );
}

export default Header;
