import { NavLink } from "react-router-dom";
import cl from "./Header.module.css";

function Nav({ onNavLinkClick }) {
  return (
    <ul>
      <li>
        <NavLink
          to={"/"}
          className={({ isActive }) => (isActive ? cl.active : "")}
          onClick={onNavLinkClick}  // Close the menu when clicked
        >
          HOME
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"/about"}
          className={({ isActive }) => (isActive ? cl.active : "")}
          onClick={onNavLinkClick}  // Close the menu when clicked
        >
          ABOUT US
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"/portfolio"}
          className={({ isActive }) => (isActive ? cl.active : "")}
          onClick={onNavLinkClick}  // Close the menu when clicked
        >
          PORTFOLIO
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"/purchase"}
          className={({ isActive }) => (isActive ? cl.active : "")}
          onClick={onNavLinkClick}  // Close the menu when clicked
        >
          ORDER
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"/contact"}
          className={({ isActive }) => (isActive ? cl.active : "")}
          onClick={onNavLinkClick}  // Close the menu when clicked
        >
          CONTACT US
        </NavLink>
      </li>
    </ul>
  );
}

export default Nav;
